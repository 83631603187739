<template>
<div>
   <!-- <table>
        <tr>
            <td>
                <div class="notice blockInfo ">
                    <h2>
                        Hello {{result.fullName}},
                    </h2>
                    <p> Welcome to Acctopus Degust.</p>
&lt;!&ndash;
                    {if result.nType eq 3}  {* Normal User *}
&ndash;&gt;
                    <template v-if="result.nType == '3'">
                     <template v-if="typeof result.project!='undefined'">
                    <p>You have already been assigned to at least one project. Under "<a
                            href="serve.php?f=administration&f2=projectAdministration">My Projects</a>" in the "Administration" menu
                        you
                        can check the projects you have access to.</p>
                    <p>If you do not find the desired project, please contact your Organisation Administrator or Manager to get
                        access.</p>
                    <p>Also make sure to complete your account information under "<a
                            href="serve.php?f=administration&f2=userSelfService&f3=myAccount">My Account</a>" in the
                        "Administration" menu.</p>
                        </template>
                        <template v-else>
                     <p> So far, no project has been assigned to you. Please contact your Organisation Administrator or Manager
                        to
                        get access to a project.</p>
                    <p>Also make sure to complete your account information under "<a
                            href="serve.php?f=administration&f2=userSelfService&f3=myAccount">My Account</a>" in the
                        "Administration"
                        menu.</p>
                        </template>
                    </template>
                    <template  v-else-if="(result.nType == '1') ||  (result.nType == '2') " >
                    &lt;!&ndash;{* OA / Manager !*}&ndash;&gt;
                        <template v-if="typeof result.project!='undefined'">
                    <p>You have already been assigned to at least one project. Under "<a href="?f=administration&f2=projectAdministration">My Projects</a>" in the "Administration" menu you
                        can check the projects you have access to.</p>
                    <p>If you do not find the desired project, please contact your Organisation Administrator or create your own
                        project under "<a href="?f=administration&f2=myOrganisationProject">Projects</a>" in the "Administration" menu.</p>
                    <p>Also make sure to complete your account information under "<a href="?f=administration&f2=userSelfService&f3=myAccount">My Account</a>" in the "Administration" menu.</p>
                        </template>
                        <template v-else>

                    <p>So far, you have not been assigned to any project. Please contact your Organisation Administrator to get
                        access to a project or create your own project under "<a href="?f=administration&f2=myOrganisationProject">Projects</a>" in the "Administration" menu.</p>
                    <p>Also make sure to complete your account information under "<a href="?f=administration&f2=userSelfService&f3=myAccount">My Account</a>" in the "Administration" menu.
                    </p>
                        </template>
                    </template>

                    <p>Please contact us with any questions, we are always happy to help.</p>
                    <p> Thank you,</p>
                    <p>your Acctopus team</p>
                   </div>
            </td>
        </tr>
    </table>
    <template v-if="result.xtraInfo !=''">

        <table style="margin-top: 10px;">
        <tr>
            <td>
                <div class="notice blockInfo successInfo"  v-html="result.xtraInfo">
                </div>
            </td>
        </tr>
    </table>
</template>
    -->
   <!-- <v-card
            class="mx-auto"
            max-width="625px"
    >
        <v-card-text>
            <p class="text-h6 text&#45;&#45;primary">
                Hello {{result.fullName}}
            </p>
            <p>We are excited to welcome you to the Acctopus Degust user community.</p>
            <template  v-if="result.nType == '1' || result.nType == '2'" >
                &lt;!&ndash;{* OA / Manager !*}&ndash;&gt;
                <template v-if="typeof result.project!='undefined'">
                   <p>You have been invited to at least one project. You can check your projects under <a href="?f=administration&f2=projectAdministration">My Projects</a> in the Administration menu.<br>
                       To work in more Degust projects, Degust project administrators will send you further invitations.
                   </p>
                    <p>You may also start your own project, which you need to establish under <a href="?f=administration&f2=projectAdministration">My Projects</a> in the Administration menu.</p>
                    <tfaInfoContent></tfaInfoContent>
                </template>
                <template v-else>
                    <p>To work in a Degust project, a project administrator must invite you to a project.
                        You may also start your own project, which you need to establish under <a href="?f=administration&f2=myOrganisationProject">My Projects</a> in the Administration menu.
                    </p>
                   <tfaInfoContent></tfaInfoContent>
                </template>
            </template>

            <template v-if="result.nType == '3'">
                <template v-if="typeof result.project!='undefined'">
                    <p>You have been invited to at least one project. You can check your projects under <a href="?f=administration&f2=projectAdministration">My Projects</a> in the Administration menu.</p>
                    <p> To work in more Degust projects, Degust project administrators will send you further invitations.</p>
                    <tfaInfoContent></tfaInfoContent>
                </template>
                <template v-else>
                    <p>To work in a Degust project, a project administrator must invite you to a project.</p>
                    <tfaInfoContent></tfaInfoContent>
                </template>
            </template>
            <p>Please contact us if you have any questions, we will be happy to help you.</p>
            <p>Thank you,</p>
            <p>your Acctopus team</p>
        </v-card-text>

    </v-card>-->
    <!-- New changes -->

    <v-card
            class="mx-auto"
            max-width="715px"
    >
        <v-card-text>
            <p class="text-h6 text--primary">Hello {{result.fullName}}</p>
            <p style="margin:0px;font-weight:bold;">We are excited to welcome you to the Acctopus Degust user community.</p>
            <p style="font-weight:bold;">If <span style="color: green;">you are new to Degust</span>, we recommend reading this in full.</p>

            <p  style="margin:0px;">To kickstart your experience with Degust, we have <router-link to="?f=administration&f2=dashboard">provided a test suite (called project)</router-link> with some tests and graphs. These tests are real tests, running on some of our Explorers in a tiny demo Project. Keep in mind, there are enterprises, managing thousands of Explorers using Degust, which will give a lot more output.</p>
              <p> The Explorers and their local statistics can be viewed under <router-link to="?f=administration&f2=explorers">Explorers</router-link>.</p>

            <p> You can open and view the test configuration and evaluation at <router-link to="?f=testing&f2=configureAndRunTests">Configure and Run Tests</router-link>. Later, you may copy them to one of your own projects and modify them to your requirements using your own Explorers.</p>

            <p>To create your own Explorer, you may <router-link to="?f=administration&f2=explorers">follow this link</router-link> to download the most current version of our free-of-charge Explorer image for your Raspberry Pi 4 or Pi 3B+. While creating the OS image, we are creating a project and some tests, scheduled to run on Explorers running this customized OS.</p>

            <p>At any time, you can modify this new project or its tests or create your own project under <router-link to="?f=administration&f2=projectAdministration">My Projects</router-link> in the Administration menu. Here you can also add members (coworkers or customers) to your project.</p>

            <p>If other Degust users inviting you to existing projects, you may switch to these projects using the project and organization switcher in the top-right menu of the Degust Cockpit.</p>

            <p>You may alter your personal profile under <router-link to="?f=administration&f2=userSelfService&f3=myAccount">My Account</router-link> where you add additional security including the two-factor authentication.</p>

            <p>Please contact us if you have any questions, we will be happy to help you.</p>
            <p style="margin:0px;">Thank you</p>
            <p>Your Acctopus team</p>
        </v-card-text>
       <div class="ml-4 pb-7">
           <router-link to="?f=administration&f2=dashboard">
           <primaryButton
                   type="button"
                   label="Go to Dashboard"
                   color="accent"
                   id="dashboard"
           ></primaryButton>
           </router-link>
       </div>

    </v-card>
</div>
</template>
<script>
  //import tfaInfoContent from "@/components/tfaInfoContent.vue";
   import primaryButton from "@/commonComponents/primaryButton.vue";
  export default {
    props: ["result"],
    data:function(){
      return {
      };
    },
    components:{
      primaryButton
      //tfaInfoContent
    },
    methods: {

    },
  };
</script>
